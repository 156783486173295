// Here you can add other styles
.btn:focus, button:focus, .btn.focus, button.focus {
    box-shadow: none;
}


.sidebar-minimized {
    .sidebar {
        .nav {
            .nav-item:hover > .nav-link, .nav-icon {
                background-color: #fff !important;
                color: #0a9be1 !important;
            }
            .nav-dropdown:hover {
                background-color: #0a9be1 !important;
                .nav-dropdown-items {
                    background-color: #fff !important;
                }
            }
        }
    }
}

.sidebar {
    background: #fff;
    color: #000;
    border-right: 1px solid #f1f1f1;
    .nav-link {
        .badge {
            background-color: #fff !important;
            color:#e91e63 !important;
        }
    }
    .nav-link:hover.nav-dropdown-toggle::before {
        background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
    }
    .nav-dropdown.open {
        background: #fff !important;
        .nav-dropdown-items {
            padding-left: 15px;
        }
    }
    .sidebar-minimizer {
        background-color: #0a9be1 !important;
        color: #fff !important;
        border-radius: 0px;
        &:hover {
            background-color: #fff;
            color: #73818f;
        }
    }
    .sidebar-minimizer::before {
        background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E")
    }
}
.main {
    .breadcrumb {
        border-bottom:none;
        background: #fafafa;
        .breadcrumb-item {
            font-size: 18px;
            a {
                color:#0a9be1;
            }
        }
    }
}
.app-header {
    height: 60px;
    border-bottom:none
}
.header-fixed .app-body {
    margin-top: 62px !important;
}
#loginPage {
    background-image: url('/assets/bg_login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.react-autosuggest__input,
.react-autosuggest__input--open {
    width: 250px;
    height: 45px;
    border: 0;
    border-bottom: 1px solid;
}
.react-autosuggest__suggestions-list {
    padding: 0;
    border: 1px solid #ccc;
    border-top: 0;
    width: 250px;    
    position: absolute;
    background: #fff;
    z-index: 1;
    li {
        list-style: none;
        border-bottom: 0.1px solid #ccc;
    }
}