@import "variables";

// override antd

// color ~ hover, focus
body {
  .ant-btn:hover,
  .ant-btn:focus {
    color: $primary-color;
    border-color: $primary-color;
    background: #fff;
  }

  .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: $primary-color;
  }

  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus,
  .ant-checkbox-checked::after,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-input-number:hover,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-input:hover,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-picker:hover,
  .ant-picker-focused,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $primary-color;
  }

  .ant-picker.ant-picker-disabled {
    border-color: #d9d9d9;
  }

  .ant-input-number:focus,
  .ant-input-number-focused,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba($primary-color, 0.35);
  }

  .ant-picker-focused,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $primary-color;
  }

  .ant-picker-header-view button:hover {
    color: $primary-color;
  }

  .ant-picker-range .ant-picker-active-bar,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-radio-inner::after {
    background-color: $primary-color;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: rgba($primary-color, 0.15);
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: rgba($primary-color, 0.2);
  }

  .ant-radio-checked::after {
    border: 1px solid $primary-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }

  .container-booking-detail
    .body
    .line-status
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background: $primary-color;
  }

  // .ant-pagination .ant-pagination-item.ant-pagination-item-active {
  //   background: rgba($primary-color, 0.2);

  //   a {
  //     color: $primary-color;
  //   }
  // }

  // select
  .ant-steps-dot .ant-steps-item-tail::after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    width: 100%;
    height: 2px;
    margin-left: 0;
  }

  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 3px;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #2d3540;
  }

  .ant-select {
    .ant-select-selector {
      // min-height: $height-25 !important;

      .ant-select-selection-search,
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }
  }

  .fix-h225 {
    .ant-select {
      .ant-select-selector {
        min-height: $height-225 !important;
      }
    }
  }

  .ant-input-number {
    min-height: $height-25 !important;
    display: inline-flex;
    align-items: center;
  }

  .fix-h225 {
    .ant-input-number {
      min-height: $height-225 !important;
    }
  }

  // width height
  .ant-input-group-addon {
    .ant-btn-icon-only {
      width: $height-25 !important;
      min-height: $height-25 !important;
    }
  }

  .fix-h225 {
    .ant-input-group-addon {
      .ant-btn-icon-only {
        width: $height-225 !important;
        min-height: $height-225 !important;
      }
    }
  }

  .ant-picker {
    // min-height: $height-25 !important;
  }

  .fix-h225 {
    .ant-picker {
      min-height: $height-225 !important;
    }
  }

  .ant-space {
    &.ant-space-vertical {
      .ant-picker {
        min-height: auto !important;

        &.ant-picker-range {
          min-height: $height-25 !important;
        }
      }
    }
  }

  .fix-h225 {
    .ant-space {
      &.ant-space-vertical {
        .ant-picker {
          &.ant-picker-range {
            min-height: $height-225 !important;
          }
        }
      }
    }
  }

  .ant-input {
    border-width: 1px;
    border-color: #e0e0e0;
    border-style: solid;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    // min-height: $height-25 !important;

    .ant-input {
      min-height: auto !important;
    }
  }

  .fix-h225 {
    .ant-input,
    .ant-input-affix-wrapper {
      min-height: $height-225 !important;

      .ant-input {
        min-height: auto !important;
      }
    }
  }

  .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    min-height: unset !important;
  }

  .ant-select-multiple .ant-select-selection-item-remove {
    display: flex;
  }
}
