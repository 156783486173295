// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Main styles for this application
@import './scss/style.scss';

//Ant Design
@import '~antd/dist/antd.css';
@import './scss/variables';

@font-face {
    font-family: 'Lato Light';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Light'), url('../../resources/font/Lato-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'Lato Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Bold'), url('../../resources/font/Lato-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    src: local('Lato'), url('../../resources/font/Lato-Regular.ttf') format('ttf');
}

.card-header {
    display: flex;
    align-items: center;

    .card-header-actions {
        flex: 1;
        text-align: right;
    }
}

.table-responsive {
    overflow-x: unset;
}

.isofh-page .pagination .page-item:focus {
    outline: 0;
}

.size-of-page {
    height: 35px;
    width: 85px;
}

.color-tags {
    border: 1px solid #a9a9a9 !important;
    border-radius: 5px;
    padding: 3px;
    margin-bottom: 18px;
    text-align: left;

    span {
        span {
            color: #000;
            background-color: #d7d7d7;
            border-radius: 4px;
            border: 1px solid #d7d7d7;
            margin: 2px 4px;
            padding: 1px 5px;
        }
    }

    input {
        width: 250px;
        margin-bottom: 0px;
    }
}

.sidebar {
    color: #2f3035;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;

    .icon {
        display: inline-block;
        width: 20px;
    }

    a.nav-link.nav-link-sub {
        font-size: 14px;
        text-transform: capitalize;
        font-weight: normal;

        &:hover,
        &.active,
        &:active {
            color: #166950;
            background: #c3e6db;
            box-shadow: inset -3px 0px 0px #166950;
        }
    }

    a.nav-link {
        display: flex;
        align-items: center;
        padding: 13px 15px 13px 15px;
        transition: 0.3s ease;
        background-repeat: no-repeat;
        color: #2f3035;
        background-position: 15px center;

        .icon {
            margin-right: 10px;
            width: 20px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
        }

        > :last-child {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }

        .nav-icon {
            display: none;
        }

        &:hover,
        &.active,
        &:active {
            background-color: transparent;
            color: #166950;

            .icon {
                filter: invert(29%) sepia(18%) saturate(2217%) hue-rotate(114deg) brightness(97%) contrast(83%);
            }

            .nav-icon {
                color: #0a9be1 !important;
            }
        }
    }

    .icon-menu {
        margin-right: 7px;
    }
}

.isofh-error {
    font-size: 13px;
    color: red;
    text-align: left;
    display: block;
}

.select-group {
    margin-bottom: 0px;
}

.show-full-text {
    .ant-select-item-option-content {
        white-space: normal;
    }
}

html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main,
html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .app-footer,
html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 240px;
}

.ant-form-vertical .ant-form-item-label {
    padding-bottom: 4px;
}

.ant-form-item {
    padding-bottom: 0 !important;
    margin-bottom: 0.5em !important;

    .ant-form-item-label {
        width: 100%;
        flex: 0 0 100%;
        text-align: left;

        label {
            font: inherit;
            color: inherit;
            height: auto;

            &.ant-form-item-required {
                &::before {
                    content: unset !important;
                }

                &::after {
                    content: '*' !important;
                    display: block !important;
                    color: #ff4d4f;
                }
            }

            // &::after {
            //   content: "";
            // }
        }
    }
}

.ant-modal {
    .ant-modal-content {
        .ant-modal-header {
            border: none;
            padding: 1.25rem 1rem;

            .ant-modal-title {
                text-align: center;
                color: #2d3540;
                font-size: 1.25rem;
                line-height: 1.5rem;
                font-weight: 700;
            }
        }

        .ant-modal-footer {
            button {
                background: #d30126;
                padding: 5px 20px;
                color: #fff;
            }

            .ant-btn-primary {
                background: #3161ad !important;
                padding: 5px 20px;
            }

            .ant-btn-exit {
                background: #ffffff !important;
                color: #000;
                padding: 5px 20px;

                &:hover {
                    color: #1890ff;
                }
            }

            // .btn-foodter-modal .ant-modal .ant-modal-content .ant-modal-footer button {
            //   background-color: white;
            //   color: #000;
            // }
        }
    }
}

.ant-btn {
    border-radius: 4px;
}

.ant-input {
    border-radius: 4px !important;
}

.ant-select-selector {
    border-radius: 4px;
}

.validate,
.error {
    color: red;
    margin-bottom: -22px;
    margin-left: 10px;
}

.ant-calendar-picker {
    width: 100%;
}

.ant-form-item-control {
    .ant-form-item-control-input {
        .ant-form-item-control-input-content {
            .ant-calendar-picker {
                width: 100%;
                border-radius: 4px;
            }

            .ant-input-number {
                width: 100%;
            }
        }
    }
}

.ant-form-item-control-input-content {
    & .ant-input-affix-wrapper.ant-input-password {
        border-radius: 4px;
    }

    input {
        border-radius: 4px;
    }
}

.ant-select {
    .ant-select-selector {
        border-radius: 4px !important;
    }
}

.ant-input[disabled] {
    color: #262626;
}

.ant-input-number-input[disabled] {
    color: #262626;
}

body {
    .btn-style {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8125rem;
        line-height: 1.0625rem;
        text-align: center;
        color: #fff;
        background-color: $primary-color;
        border: 1px solid rgba(0, 0, 0, 0.103176);
        border-radius: 4px;
        padding: 4px 0.75rem;
        min-height: 2rem;
        width: fit-content;
        max-width: 100%;
        gap: 8px;

        transition: background 0.25s, color 0.25s, border 0.25s, transform 0.25s, box-shadow 0.25s;

        .anticon + span {
            margin-left: 0;
        }

        &.loading {
            opacity: 0.5;
            user-select: none;
            pointer-events: none;
        }

        &:hover {
            background-color: $primary-color;
            border: 1px solid rgba(0, 0, 0, 0.103176);
            color: #fff;
            transform: translateY(-3px);
            box-shadow: 0px 5px 5px -3px rgba($color: #000, $alpha: 0.4);
        }

        &.btn-style2 {
            color: #2d3540;
            background: #fff;
            border: 1px solid #69788c;
        }

        &.btn-style3:hover,
        &.btn-style3 {
            color: #fff;
            background: #ff2d54;
            border: 1px solid #ff2d54;
        }
    }

    .rangepicker-custom-1.ant-picker-dropdown
        .ant-picker-panels
        .ant-picker-panel:first-child
        .ant-picker-header-super-next-btn,
    .rangepicker-custom-1.ant-picker-dropdown
        .ant-picker-panels
        .ant-picker-panel:first-child
        .ant-picker-header-next-btn {
        visibility: visible !important;
    }

    .rangepicker-custom-1.ant-picker-dropdown .ant-picker-panels .ant-picker-panel + .ant-picker-panel {
        display: none;
    }

    .category-value {
        text-decoration-line: none !important;
        font-weight: 400 !important;
    }

    .ant-form-horizontal .ant-form-item-control {
        flex: 1 1 auto !important;
    }

    .select-design-style {
        .ant-select-selector {
            padding-top: 2.25rem !important;
            padding: 0;
            border: none !important;
            cursor: pointer;

            .ant-select-selection-overflow {
            }

            &::before {
                content: '';
                background: #fff;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                width: 100%;
                height: 2.25rem;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }

            &::after {
                content: 'Chọn khoa';
                color: rgba(0, 0, 0, 0.33);
                font-size: 0.8125rem;
                line-height: 1.0625rem;
                position: absolute;
                top: 1.125rem;
                transform: translateY(-50%);
                left: 0.75rem;
                width: auto;
                max-width: calc(100% - 1.5rem);
                margin: 0;
            }

            .ant-select-selection-item {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #0762f7;
                border-radius: 3px;

                font-size: 0.8125rem;
                line-height: 1.25rem;
                color: #172b4d;
                margin-top: 2px;
                margin-bottom: 2px;
                margin-right: 4px;
            }
        }
    }

    .ant-form-horizontal .ant-form-item-control {
        flex: 1 1 auto !important;
    }

    .table-actions {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .action-btn {
        width: 1.25rem;
        height: 1.25rem;
        background: #dde0e4;
        color: #69788c;
        border-radius: 6px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 2px;

        & > span {
            font-size: 0.75rem;
        }

        img {
            height: 100%;
            max-height: 0.875rem;
            width: auto;
        }
    }
    .action-btn-2 {
        background: none;
        border: none;
        padding: 0px 4px;
    }

    .form-wrapper-custom {
        .ant-form-item {
            display: block;
            margin-bottom: 0.75rem;

            .ant-form-item-label {
                padding: 0;

                label {
                    font-weight: normal;
                    font-size: 0.875rem;
                    line-height: 1.0625rem;
                    color: #2d3540;
                    margin-bottom: 4px;
                    height: auto;

                    &.ant-form-item-required {
                        &::before {
                            content: unset;
                            margin: 0;
                        }

                        &::after {
                            content: '*';
                            font: inherit;
                        }
                    }
                }
            }

            .ant-form-item-control {
                .ant-form-item-control-input {
                    .ant-form-item-control-input-content {
                        .ant-select.ant-select-single {
                            .ant-select-selector {
                                height: 2.25rem;
                                display: flex;
                                align-items: center;

                                .ant-select-selection-search {
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-custom-styles {
        z-index: 1001;
    }

    .select-time-btn:hover {
        border-color: $primary-color !important;
    }

    .select-time-btn:hover {
        border-color: $primary-color !important;
    }

    .antd-multi-select-ant-custom {
        &.antd-multi-select-ant-custom-item100 {
            .ant-select-selection-overflow {
                display: block;

                .ant-select-selection-item {
                    .ant-select-selection-item-remove {
                        margin-left: auto;
                    }
                }
            }
        }

        .ant-select-selector {
            padding-top: $height-25 !important;
            height: auto !important;
            padding: 0;
            border: none !important;
            cursor: pointer;

            .ant-select-selection-overflow {
            }

            &::before {
                content: '';
                background: #fff;
                border-radius: 4px;
                width: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: $height-25;
                border: 1px solid #e0e0e0;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            &::after {
                color: rgba(0, 0, 0, 0.33);
                font-size: 0.8125rem;
                line-height: 1.0625rem;
                position: absolute;
                top: calc(#{$height-25}/ 2);
                transform: translateY(-50%);
                left: 0.75rem;
                width: auto;
                max-width: calc(100% - 1.5rem);
                margin: 0;
            }

            .ant-select-selection-item {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), $primary-color;
                border-radius: 3px;

                font-size: 0.8125rem;
                line-height: 1.25rem;
                color: #172b4d;
                margin-top: 2px;
                margin-bottom: 2px;
                margin-right: 4px;
            }
        }

        &.ant-select-focused {
            &:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
                .ant-select-selector {
                    box-shadow: unset !important;

                    &::before {
                        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
                    }
                }
            }

            .ant-select-selector {
                &::before {
                    border-color: $primary-color;
                }
            }
        }

        &:hover {
            .ant-select-selector {
                &::before {
                    border-color: $primary-color;
                }
            }
        }
    }

    .fix-h225 {
        .antd-multi-select-ant-custom {
            .ant-select-selector {
                padding-top: $height-225 !important;
                height: auto !important;

                &::before {
                    height: $height-225;
                }

                &::after {
                    top: calc(#{$height-225} / 2);
                }
            }
        }
    }

    .min-height25 {
        min-height: $height-25;
    }

    .min-height225 {
        min-height: $height-225;
    }

    .label-noti {
        font-size: 0.75rem;
        line-height: 0.875rem;
        padding: 4px 8px;
        display: inline-flex;

        &.label-in-table {
            border-radius: 28px;
        }

        &.label-progress {
            color: #3161ad;
            background: #e6f6f2;
        }

        &.label-success {
            color: #0a9be1;
            background: #e7f5fc;
        }

        &.label-connect {
            color: #09da56;
            background: #e7f5fc;
        }

        &.label-warning {
            color: #bf7b05;
            background: #fff6d1;
        }

        &.label-error {
            color: #fc4a5f;
            background: rgba(252, 74, 95, 0.2);
        }

        &.label-not-come {
            color: #2d3540;
            background: rgba(0, 0, 0, 0.1);
        }

        &.label-completed {
            color: #52c41a;
            background: rgba(82, 196, 26, 0.3);
        }
    }

    .react-calendar {
        width: 376px;
        max-width: 100%;
        background: #ffffff;
        border: none;

        .react-calendar__navigation {
            height: 57px;
            margin-bottom: 0;

            button {
                min-width: 80px;

                &[disabled] {
                    background-color: #ddd;
                    opacity: 0.35;
                }

                &.react-calendar__navigation__prev2-button,
                &.react-calendar__navigation__next2-button {
                    display: none;
                }

                &.react-calendar__navigation__arrow {
                    font-size: 14px;
                }

                &.react-calendar__navigation__label {
                    text-transform: capitalize;
                    font-size: 14px;
                    line-height: 17px;
                    color: #2d3540;
                    pointer-events: none;
                }
            }
        }

        .react-calendar__viewContainer {
            .react-calendar__month-view__weekdays {
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-width: 1px 0 1px 0;
                padding: 0 10px;

                .react-calendar__month-view__weekdays__weekday {
                    padding: 7px 10px 8px;

                    abbr {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        color: #2d3540;
                        text-decoration: none;
                    }
                }
            }

            .react-calendar__month-view__days {
                row-gap: 20px;
                padding: 15px 10px 30px;

                .react-calendar__tile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff0;

                    &.react-calendar__month-view__days__day--weekend {
                        color: inherit;
                    }

                    abbr {
                        flex: 0 0 28px;
                        width: 28px;
                        height: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        border: 1px solid #fff0;
                        transition: all 0.25s;
                        user-select: none;
                    }

                    &:not(:disabled) {
                        abbr {
                            background: #fff;
                            border-color: #3161ad;
                        }
                    }

                    &:not(:disabled):hover,
                    &.react-calendar__tile--active {
                        abbr {
                            background-color: #0a9be1;
                            border: 1px solid #0a9be1;
                            color: #fff;
                        }
                    }

                    padding: 0 !important;

                    &:disabled {
                        background-color: #fff0;
                        opacity: 0.75;
                    }
                }
            }
        }
    }
}

.ant-table-wrapper {
    //margin-bottom: 2rem;

    &.logs-table {
        .ant-table {
            .ant-table-container {
                .ant-table-body {
                    @media screen and (max-width: 767px) {
                        max-height: calc(100vh - 21rem) !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 575px) {
        margin-bottom: 0;
    }

    .ant-table {
        font-family: 'Lato', sans-serif;

        .ant-table-container {
            // fixed
            .ant-table-header {
                .ant-table-thead {
                    .ant-table-cell-scrollbar {
                        box-shadow: unset;
                    }

                    .ant-table-cell {
                        font-size: 0.8125rem;
                        font-weight: 700;
                        padding: 7px 10px 8px;
                        background-color: #e7f5fc;

                        &:first-child {
                            padding-left: 1.125rem;
                        }

                        &::before {
                            content: unset;
                        }
                    }
                }
            }

            .ant-table-body {
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: unset;
                    border-radius: 7px;
                    background-color: #f1f1f1;
                }

                &::-webkit-scrollbar {
                    width: 4px !important;
                    height: 4px !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c1c1c1 !important;
                }

                max-height: calc(100vh - 16.5rem) !important;
                overflow: auto !important;

                @media screen and (max-width: 767px) {
                    max-height: 70vh !important;
                }

                // @media screen and (max-width: 575px) {
                //   max-height: 70vh !important;
                // }

                .ant-table-cell {
                    font-size: 0.8125rem;
                    line-height: 1.0625rem;
                    color: #2d3540;
                    font-weight: 400;
                    border-bottom: 1px solid #d9dbe9;
                }

                .ant-table-tbody {
                    .ant-table-row {
                        &:last-child {
                            .ant-table-cell {
                                border-bottom: none;
                            }
                        }

                        .ant-table-cell {
                            padding: 9px 10px;
                            background-color: #fff;

                            &:first-child {
                                padding-left: 1.125rem;
                            }

                            .show-detail {
                                font-weight: 400;
                                text-decoration-line: none !important;
                                color: $primary-color;
                            }

                            .booking-status {
                                padding: 4px 8px;
                                font-size: 0.75rem;
                                line-height: 0.875rem;
                                width: fit-content;
                                max-width: 100%;
                                border-radius: 20px;

                                &.success {
                                    color: #3161ad;
                                    background: rgba(49, 97, 173, 0.2);
                                }

                                &.error {
                                    color: rgb(252, 74, 95);
                                    background: rgba(252, 74, 95, 0.2);
                                }

                                &.warning {
                                    color: #bf7b05;
                                    background: #fff6d1;
                                }
                            }
                        }
                    }
                }
            }

            // not fixed
            .ant-table-content {
                overflow-x: auto;

                .ant-table-cell {
                    font-size: 0.8125rem;
                    line-height: 1.0625rem;
                    color: #2d3540;
                    font-weight: 400;
                    border-bottom: 1px solid #d9dbe9;
                }

                .ant-table-thead {
                    .ant-table-cell {
                        font-weight: 700;
                        padding: 7px 10px 8px;
                        background-color: #e7f5fc;

                        &:first-child {
                            padding-left: 1.125rem;
                        }

                        &::before {
                            content: unset;
                        }
                    }
                }

                .ant-table-tbody {
                    .ant-table-row {
                        &:last-child {
                            .ant-table-cell {
                                border-bottom: none;
                            }
                        }

                        .ant-table-cell {
                            padding: 9px 10px;
                            background-color: #fff;

                            &:first-child {
                                padding-left: 1.125rem;
                            }

                            .show-detail {
                                font-weight: 700;
                                text-decoration-line: underline;
                                color: $primary-color;
                            }

                            .booking-status {
                                padding: 4px 8px;
                                font-size: 0.75rem;
                                line-height: 0.875rem;
                                width: fit-content;
                                max-width: 100%;
                                border-radius: 20px;

                                &.success {
                                    color: #3161ad;
                                    background: rgba(49, 97, 173, 0.2);
                                }

                                &.error {
                                    color: rgb(252, 74, 95);
                                    background: rgba(252, 74, 95, 0.2);
                                }

                                &.warning {
                                    color: #bf7b05;
                                    background: #fff6d1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-pagination {
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    position: sticky;
    padding: 4px 1.25rem !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8125rem;
    line-height: 1.0625rem;
    color: #2d3540;
    font-weight: 400;
    border-top: 1px solid #d9dbe9;

    .ant-pagination-total-text {
        order: 1;
    }

    .ant-pagination-options {
        order: 2;
        margin-left: 1.75rem;
        margin-right: auto;

        &::before {
            content: 'Hiển thị';
            font: inherit;
            margin-right: 8px;
        }

        .ant-pagination-options-size-changer:not(.ant-select-dropdown-hidden) {
            .ant-select-dropdown {
                // top: -108px !important;
            }
        }
    }

    & > * {
        order: 3;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        display: inline-flex;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        margin: 0;

        button {
            border: none;
            outline: none;
            background-color: #fff0;

            svg {
                vertical-align: unset;
            }
        }
    }

    .ant-pagination-item {
        border-radius: 3px;
        background-color: #fff0;
        border: none;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &.ant-pagination-item-active {
            background: rgba(39, 92, 171, 0.1);

            a {
                color: #2d3540;
            }
        }
    }

    @media screen and (max-width: 767px) {
        justify-content: center;
        flex-wrap: wrap;
        padding: 14px 1rem 4px !important;

        .ant-pagination-total-text {
            order: 4;
            flex: 0 0 100%;
            margin: 0;
            text-align: center;
        }

        .ant-pagination-options {
            order: 5;
            display: flex;
            flex: 0 0 100%;
            margin: 0;
            justify-content: center;
            align-items: center;
            margin: 4px;
            display: none;
        }
    }
}

.body-page {
    padding: 9px 18px 7px 12px;

    .body-data {
        background: #ffffff;
        border: 1px solid #dfe1e6;
        border-radius: 4px;
    }

    .btn-none {
        border: none !important;
        background: transparent !important;
        outline: none !important;
        padding: 0px;
        margin-right: 5px;
    }

    .design-status {
        border-radius: 28px;
        padding: 4px 8px;
    }

    .wrap-tool-doctor {
        display: inline-block;
    }

    .NOT_YET_SEND_CONFIRM,
    .WAIT_CONFIRM,
    .WAIT_APPROVE,
    .UNREQUESTED {
        color: #bf7b05;
        background: #fff6d1;
    }

    .ACTIVE,
    .APPROVED {
        color: #0a9be1;
        background: rgba(10, 155, 225, 0.1);
    }

    .INACTIVE_FORCED,
    .INACTIVE,
    .REJECT {
        color: #fc4a5f;
        background: rgba(252, 74, 95, 0.2);
    }
}

.btns-style {
    display: flex;
    gap: 8px;
}

.form-btns {
    justify-content: flex-end;
}

.btn-action {
    background: #dde0e4;
    border-radius: 6px;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    cursor: pointer;
    transition: 350ms;
    margin-right: 8px;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 7px 7px -3px rgba(0, 0, 0, 0.4);
        border: none !important;
    }

    &:active {
        transform: translateY(2px);
        box-shadow: 0px 4px 4px -3px rgba(0, 0, 0, 0.4);
        border: none !important;
    }

    &:focus {
        outline: none;
    }

    &.disabled {
        user-select: none;
        pointer-events: none;
        opacity: 0.3;
    }
}

.color-blue {
    color: rgb(10, 155, 225);
    cursor: pointer;
}

.rangepicker-custom-1.ant-picker-dropdown
    .ant-picker-panels
    .ant-picker-panel:first-child
    .ant-picker-header-super-next-btn,
.rangepicker-custom-1.ant-picker-dropdown .ant-picker-panels .ant-picker-panel:first-child .ant-picker-header-next-btn {
    visibility: visible !important;
}

.rangepicker-custom-1.ant-picker-dropdown .ant-picker-panels .ant-picker-panel + .ant-picker-panel {
    display: none;
}

.user-select-none {
    user-select: none;
}

.poiter-event-none {
    pointer-events: none;
}

.text-link {
    cursor: pointer;
    color: #0a9be1;
    text-decoration-line: underline;
}

.ant-table-wrapper
    .ant-table
    .ant-table-container
    .ant-table-body
    .ant-table-tbody
    .ant-table-row
    .ant-table-cell:first-child {
    padding: 9px !important;
}

.ant-table-wrapper .ant-table .ant-table-container .ant-table-header .ant-table-thead .ant-table-cell:first-child {
    padding: 9px !important;
}

.wrap-check-all {
    background: rgba(105, 120, 140, 0.2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 16px;
    gap: 23px;
    font-size: 14px;
    line-height: 17px;

    .text {
        font-style: italic;
        font-weight: 400;
    }

    button {
        background: #0a9be1;
        border-radius: 4px;
        color: white;
        font-weight: 700;

        svg {
            margin-left: 10px;
        }
    }
}

.ant-modal-close:focus,
.ant-modal-close:hover {
    outline: none;
}

.container-custom {
    height: calc(100vh - 70px);
    width: 100%;

    .header {
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        width: 100%;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
        }

        .button-container {
            display: flex;
            gap: 12px;
        }
    }

    .body {
        padding: 12px;
        height: calc(100vh - 110px);
        width: 100%;
        background-color: #fafafa;
    }

    .filter {
        .filter-popover {
            display: flex;
            align-items: center;
            border-radius: 4px 0px 0px 4px;
            background-color: rgba(10, 155, 225, 0.1);

            .filter-overlay {
            }

            span {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }

        .ant-select-selection-item {
            display: block !important;
            text-align: start;
        }

        // .ant-select-selector {
        //     border-radius: 0px !important;
        // }

        .date-filter {
            width: 100%;
        }
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        // gap: 8px;
        .ant-tag {
            background: rgba(10, 155, 225, 0.3);
            border-radius: 3px;
            font-size: 14px;
            line-height: 20px;
            border: 0;
            display: inline-flex;
            align-items: center;
            // gap: 6px;
            padding: 0px 4px;
            margin-top: 4px;
        }
    }
}

.form-modal-custom {
    &.ant-modal {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;

        .ant-modal-content {
            border-radius: 6px;

            .ant-modal-header {
                border: none;
                padding: 18px;
                text-transform: uppercase;
                justify-content: space-between;
                display: flex;
                font-size: 16px;
                background: #0a9be11a;

                .ant-modal-title {
                    text-align: left;
                    font-size: 16px;
                }
            }

            .ant-modal-body {
                padding-bottom: 18px;
                padding-top: 12px;

                .ant-input {
                    font-size: 14px;
                }
            }

            .ant-modal-footer {
                display: flex;
                justify-content: end;
            }
        }
    }
}
