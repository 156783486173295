@keyframes fadeInFromNone {
    0% {
        transform: translateY(100px);
    }

    50% {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0px);
    }
}

.modal-notification {
    position: fixed;
    bottom: 0;
    left: 12.5rem;
    z-index: 500;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: flex-end;

    .notification-container {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 8px 0px 8px 8px;
        max-width: 60%;
        width: 60%;
        animation: fadeInFromNone 0.5s ease-out;
        .tabs,
        .tab,
        .badge,
        .header,
        .btn-read {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .header {
            padding: 15px 10px;
            border-bottom: 1px solid #dddde3;
            justify-content: space-between;

            .title {
                font-weight: 700;
                font-size: 16px;
                color: #2d3540;
            }

            .btn-read {
                font-weight: 400;
                font-size: 14px;
                color: #275cab;
                cursor: pointer;
                line-height: 18px;

                img {
                    margin-right: 4px;
                }
            }
        }

        .tabs {
            justify-content: flex-start;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            .tab {
                padding: 16px 12px;
                cursor: pointer;

                .name {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #27272a;
                }

                .badge {
                    background: #ff424f;
                    border: 1px solid #ffffff;
                    border-radius: 8px;
                    padding: 0.5px 4px;
                    color: #ffffff;
                    font-weight: 700;
                    font-size: 10px;
                    min-width: 16px;
                    min-height: 16px;
                    margin-left: 4px;
                }
            }

            .tab-selected {
                border-bottom: 1px solid #0a9be1;

                .name {
                    font-weight: 700;
                    color: #0a9be1;
                }
            }
        }

        .content {
            height: 50vh;
            overflow: scroll;

            .item {
                padding: 16px 20px;
                background: #ffffff;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: row;
                width: 100%;
                cursor: pointer;
            }

            .item-selected {
                background: rgba(10, 155, 225, 0.05);
            }

            .icon {
                min-width: 40px;
                height: 40px;
                object-fit: contain;
            }

            .describe {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 16px;
                text-align: left;

                span:not(:last-child) {
                    margin-bottom: 8px;
                }

                .title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #2f3035;
                }

                .description {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #2f3035;
                }

                .date {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #9393aa;
                }
            }
        }
    }
}
